/**
 * CONSTANTS GO HERE
 *
 *
 * USAGE OF GATSBY ENV VARIABLES:
 * https://www.gatsbyjs.com/docs/environment-variables/
 */

import { Cutoffs, GroupingCutoffs } from "./@types/IZone"

// export const ENV: string = "dev" // local | dev | prod | prod_paris

// const CONSTANTS: {
//   [env: string]: {
//     apiGatewayUrl: string
//     apiGatewayAud: string
//     mgmtEnv: "dev" | "test" | "prod"
//     origin: string
//     oauthIssuer: string
//     oauthClientId: string
//     oauthNamespace: string
//     vpnSetupPath: string
//     cameraSetupPath: string
//   }
// } = {
//   local: {
//     apiGatewayUrl: "http://localhost:2020/apigateway",
//     apiGatewayAud: "https://dev.api.crowdeagle.com.au/apigateway",
//     mgmtEnv: "dev",
//     origin: "http://localhost:8000/",
//     oauthIssuer: "dev-dcm-auth.au.auth0.com",
//     oauthClientId: "PF2f5ARdc9AEroM5l8JizVNpyiaMmfdP",
//     oauthNamespace: "https://dev.dcm.crowdeagle.com.au/",
//     vpnSetupPath: "vpnsetup-dev",
//     cameraSetupPath: "cameradriversetup-dev",
//   },
//   dev: {
//     apiGatewayUrl: "https://dev.api.crowdeagle.com.au/apigateway",
//     apiGatewayAud: "https://dev.api.crowdeagle.com.au/apigateway",
//     mgmtEnv: "dev",
//     origin: "https://dev.dcm.crowdeagle.com.au/",
//     oauthIssuer: "dev-dcm-auth.au.auth0.com",
//     oauthClientId: "PF2f5ARdc9AEroM5l8JizVNpyiaMmfdP",
//     oauthNamespace: "https://dev.dcm.crowdeagle.com.au/",
//     vpnSetupPath: "vpnsetup-dev",
//     cameraSetupPath: "cameradriversetup-dev",
//   },
//   test: {
//     apiGatewayUrl: "https://test.api.crowdeagle.com.au/apigateway",
//     apiGatewayAud: "service-apigateway",
//     mgmtEnv: "test",
//     origin: "https://test.dcm.crowdeagle.com.au/",
//     oauthIssuer: "dcm-test.eu.auth0.com",
//     oauthClientId: "f6bb3hJOAvnkQUQ354cttAis33mjmBGl",
//     oauthNamespace: "https://test.dcm.crowdeagle.com.au/",
//     vpnSetupPath: "vpnsetup-test",
//     cameraSetupPath: "cameradriversetup-test",
//   },
//   prod: {
//     apiGatewayUrl: "https://api.crowdeagle.com.au/apigateway",
//     apiGatewayAud: "service-apigateway",
//     mgmtEnv: "prod",
//     origin: "https://dcm.crowdeagle.com.au/",
//     oauthIssuer: "dcm-prod.au.auth0.com",
//     oauthClientId: "MpLUagkarhPE7ncDY6KoTcxBAyHHshOn",
//     oauthNamespace: "https://dcm.crowdeagle.com.au/",
//     vpnSetupPath: "vpnsetup",
//     cameraSetupPath: "cameradriversetup",
//   },
//   prod_paris: {
//     apiGatewayUrl: "https://eu-west-3.api.crowdeagle.com.au/apigateway",
//     apiGatewayAud: "service-apigateway",
//     mgmtEnv: "prod",
//     origin: "https://eu-west-3.dcm.crowdeagle.com.au/",
//     oauthIssuer: "dcm-prod.eu.auth0.com",
//     oauthClientId: "ykNV0ut7Q4zoWDj3hQ1qgNcqZYmQurdW",
//     oauthNamespace: "https://eu-west-3.dcm.crowdeagle.com.au/",
//     vpnSetupPath: "vpnsetup",
//     cameraSetupPath: "cameradriversetup",
//   },
// }

// export const PRODUCTION = false
// export const API_GATEWAY_URL = CONSTANTS[ENV].apiGatewayUrl

//Vpn server route (this should just be apigateay)
// export const MGMT_URL = API_GATEWAY_URL
// export const MGMT_ENV = CONSTANTS[ENV].mgmtEnv

// export const VPN_SETUP_PATH = CONSTANTS[ENV].vpnSetupPath
// export const CAMERA_SETUP_PATH = CONSTANTS[ENV].cameraSetupPath

//OAuth2 details
// export const OAUTH_REDIRECT_URL =
// typeof window !== "undefined" ? window.location.origin : CONSTANTS[ENV].origin
// export const OAUTH_ISSUER = CONSTANTS[ENV].oauthIssuer
// export const OAUTH_CLIENT_ID = CONSTANTS[ENV].oauthClientId
// export const OAUTH_AUDIENCE = CONSTANTS[ENV].apiGatewayAud
// export const OAUTH_NAMESPACE = CONSTANTS[ENV].oauthNamespace

export const DEBUG = true;

//API SETTINGS
export const ID_DEBUG = true //Enable to show entity ids on forms
export const PRODUCTION = true
// export const DEFAULT_INTERVAL = 15000
export const DEFAULT_INTERVAL = 15000
export const INTERPOLATE = false //Not used right now but keep here
export const DEFAULT_TIMEZONE = "Australia/Sydney"

//ROUTES
//Note: make sure there is a leading / slash and no trailing / slash
export const LOGIN_REDIRECT = "/app/dashboard"
export const LOGOUT_REDIRECT = "/login"
export const PW_UPDATE = "/updatepassword"
export const HOME = "/app/dashboard"
export const SETTINGS = "/app/settings"
export const REPORTS = "/app/reports"
export const USER_PROFILE = "/app/settings/userProfile"

//Risk is calculated through a point system
//E.g. Density + mood + flow points for a particular
//value cutoff would add to calcuate total risk score
export const DEFAULT_MAX_CUTOFFS: Cutoffs = {
  d: { // Density
    low: 0.6,
    med: 0.8,
    high: 1,
    riskIncreases: true,
    maxVal: 1,
    default: 0,
    lowPoints: 0,
    midPoints: 0.5,
    highPoints: 1,
    veryHighPoints: 2,
  },
  m: { // Mood
    low: 0.3,
    med: 0.5,
    high: 0.7,
    riskIncreases: true,
    maxVal: 1,
    default: 0.5,
    lowPoints: 0,
    midPoints: 0,
    highPoints: 0.5,
    veryHighPoints: 1,
  },
  f: { // Flow
    low: 0.25,
    med: 0.5,
    high: 0.75,
    riskIncreases: false,
    maxVal: 4,
    default: 0,
    lowPoints: 1.5,
    midPoints: 1,
    highPoints: 0.5,
    veryHighPoints: 0,
  },
  s: { // Distance
    low: 0.3,
    med: 0.4,
    high: 0.5,
    riskIncreases: false,
    maxVal: 3,
    default: 3,
    lowPoints: 2,
    midPoints: 1.5,
    highPoints: 1,
    veryHighPoints: 0,
  },
  c: { // Compliance
    low: 0.5,
    med: 0.75,
    high: 1,
    riskIncreases: false,
    maxVal: 1,
    default: 1,
    lowPoints: 2,
    midPoints: 1.5,
    highPoints: 1,
    veryHighPoints: 0,
  },
  h: { // Headcount
    low: 0.8,
    med: 0.9,
    high: 1,
    riskIncreases: true,
    maxVal: 50,
    default: 0,
    lowPoints: 0,
    midPoints: 0.5,
    highPoints: 1.5,
    veryHighPoints: 2,
  },
}

// Reports
export const PEDESTRIAN_DETECTIONS_EXPLAINER = `
  Pedestrian detections are the sum of all people detected by the system in 
  every measurement frame across the time period. It is an indicator of 
  relative business of an area over time. Note these are not unique detections, 
  and the same person may be detected multiple times in different frames.
`;
export const HEADCOUNT_EXPLAINER = `
  Headcount is the average number of people detected in the time period. It is 
  an indicator of the number of people in an area at different times of the day.
`;
export const DENSITY_EXPLAINER = `
  Density is a measure of the number of people to the size of the area across the 
  time period. High density is a key indicator of crowded spaces and higher risk areas.
`;
export const FLOW_EXPLAINER = `
  Flow is a measure of the speed and direction of travel by all pedestrians detected. 
  Low speeds can be an indicator of areas of restricted movement and high risk crowd 
  situations.
`;
export const MOOD_EXPLAINER = `
  Mood (or sentiment) is a measure of the happiness or otherwise of the crowd. 
  Changes in mood can be an indicator of incidents or higher risk areas.
`;
export const SOCIAL_DISTANCE_EXPLAINER = `
  Social distancing is the measure of the distance from every person detected to the 
  person nearest to them. It can indicate when there is localised crowding in a larger area.
`;
export const FLOW_LABELS = {
  STILL: 'Still (0 - 0.05m/s)',
  SLOW: 'Slow (0.05 - 0.3m/s)',
  MEDIUM: 'Medium (0.3 - 0.8m/s)',
  FAST: 'Fast (0.8 - 1.5m/s)',
  VERY_FAST: 'Very Fast (>1.5m/s)',
  NORTH: 'North',
  NORTH_EAST: 'North East',
  EAST: 'East',
  SOUTH_EAST: 'South East',
  SOUTH: 'South',
  SOUTH_WEST: 'South West',
  WEST: 'West',
  NORTH_WEST: 'North West',
}
export const FLOW_LABELS_IMPERIAL = {
  STILL: 'Still (0 - 0.16ft/s)',
  SLOW: 'Slow (0.16 - 1ft/s)',
  MEDIUM: 'Medium (1 - 2.6ft/s)',
  FAST: 'Fast (2.6 - 4.9ft/s)',
  VERY_FAST: 'Very Fast (>4.9ft/s)',
  NORTH: 'North',
  NORTH_EAST: 'North East',
  EAST: 'East',
  SOUTH_EAST: 'South East',
  SOUTH: 'South',
  SOUTH_WEST: 'South West',
  WEST: 'West',
  NORTH_WEST: 'North West',
}
export const MOOD_LABELS = {
  POSITIVE: 'Positive',
  NEUTRAL: 'Neutral',
  NEGATIVE: 'Negative',
}
export const SOCIAL_DISTANCE_LABELS = {
  D_05: '0.0m - 0.5m',
  D_1: '0.5m - 1.0m',
  D_15: '1.0m - 1.5m',
  D_2: '1.5m - 2.0m',
  D_25: '2.0m - 2.5m',
  D_25M: '2.5m+',
}
export const GOOGLE_CHARTS_DEFAULT_COLOURS = [
  "#3366CC",
  "#DC3912",
  "#FF9900",
  "#109618",
  "#990099",
  "#3B3EAC",
  "#0099C6",
  "#DD4477",
  "#66AA00",
  "#B82E2E",
  "#316395",
  "#994499",
  "#22AA99",
  "#AAAA11",
  "#6633CC",
  "#E67300",
  "#8B0707",
  "#329262",
  "#5574A6",
  "#3B3EAC"
];

export const DEFAULT_GROUP_SIZE_LIMIT = 5 //#DCM-982
export const DEFAULT_GROUP_THRESHHOLD = 1
export const DEFAULT_COMPLIANCE_CUTOFF = 1.5
export const RISK_COLOURS = ["#219653", "#f2c94c", "#f2994a", "#e8322f"] // pos, neu, neu_neg, neg

export const DEFAULT_GROUPING_CUTOFFS: GroupingCutoffs = {
  numGroups: {
    low: 1.5 / 5,
    med: 2.2 / 5,
    high: 3.1 / 5,
    riskIncreases: true,
    maxVal: 50,
    default: 0,
    lowPoints: 0,
    midPoints: 0.5,
    highPoints: 1,
    veryHighPoints: 1.5,
  },
  groupSize: {
    low: 1.5 / 5,
    med: 2.2 / 5,
    high: 3.1 / 5,
    riskIncreases: true,
    maxVal: 15,
    default: 0,
    lowPoints: 0,
    midPoints: 0.5,
    highPoints: 1,
    veryHighPoints: 1.5,
  },
  socialDistance: {
    low: 0.35,
    med: 0.5,
    high: 0.6,
    riskIncreases: false,
    maxVal: 3,
    default: 3,
    lowPoints: 1.5,
    midPoints: 1,
    highPoints: 0.5,
    veryHighPoints: 0,
  },
  compliance: {
    low: 0.35,
    med: 0.5,
    high: 0.6,
    riskIncreases: false,
    maxVal: 1,
    default: 1,
    lowPoints: 1.5,
    midPoints: 1,
    highPoints: 0.5,
    veryHighPoints: 0,
  },
}

//FOR MODULES
export const DISPLAY_DENSITY = true
export const DISPLAY_FLOW = true
export const DISPLAY_MOOD = true
export const DISPLAY_DISTANCE = false
export const DISPLAY_COMPLIANCE = true
export const DISPLAY_HEADCOUNT = true
export const DISPLAY_CUMULATIVE_COUNTING = false

//MAP CONSTANTS
export const DEFAULT_CENTER = [151.20912551879883, -33.861008021639954] as [
  number,
  number
]
export const DEFAULT_ZOOM = 17

//FOR USER SETUP
export const DEFAULT_ROLE = "user"
export const ROLES = ["user", "admin"]
export const MIN_PW_LENGTH = 15
// Auth0 custom claim for pw change ticket url
export const CLAIM_FORCE_PW_UPDATE = 'forcePasswordUpdate'

export const PROJECTION_INVERSE_INDEX = {
  X: 0,
  Y: 1,
  Z: 2
}

export const DENSITY_AREA_INDEX = {
  X: 0,
  Y: 1
}

export const DEFAULT_DASHBOARD_ID = 0
export const DASHBOARD_PATH = [
  '/app/dashboard',
]
