import { produce } from "immer"
import { getType, PayloadAction, createAction } from "typesafe-actions"
import { networkSetupActionsAsync } from "./networkSetupReducerV2"
import { auth0ActionsAsync } from "./auth0Reducer"
import { projectActionsAsync } from "./projectReducer"
import { tenantActionsAsync } from "./tenantReducer"
import { appActionsAsync } from "./appReducer"
import { getToastByErrCode } from "../../components/Alerts/getToastByErrCode"
import { getToastByType } from "../../components/Alerts/getToastByType"
import { dataV2ActionsAsync } from "./dataReducerV2"
import { reportActionsAsync } from "./reportsReducer"
import { notificationActionsAsync } from "./notifications"
import { createCustomToast } from "../../components/Alerts/createCustomToast"

/**
 * ==============================================================
 * STATE
 * ==============================================================
 */
export interface Message {
  id: string
  type: "success" | "failure"
  actionType?: string
  error?: Error
  code?: string
}

export interface MessagesState {
  messages: {
    [id: string]: any
  }
}

export const initialMessagesState: MessagesState = {
  messages: {},
}

/**
 * ==============================================================
 * ACTIONS
 * ==============================================================
 */
//Own actions
export const messagingActions = {
  createCustomMessage: createAction("@messaging/createCustomMessage", (input: {
    title:string,
    color: string,
    text: string
  }) => input)(),
}
type ValueOf<T> = T[keyof T]
export type MessagesAction = ReturnType<ValueOf<typeof messagingActions>>

/**
 * Register all async actions here,
 * This will be used to generate automatically its:
 * - fetching state
 * - messages state
 */
const asyncActions = [
  ...Object.values(networkSetupActionsAsync),
  ...Object.values(auth0ActionsAsync),
  ...Object.values(dataV2ActionsAsync),
  ...Object.values(projectActionsAsync),
  ...Object.values(tenantActionsAsync),
  ...Object.values(appActionsAsync),
  ...Object.values(reportActionsAsync),
  ...Object.values(notificationActionsAsync),
]

const asyncActionTypes: { [type: string]: "success" | "failure" } = {}
asyncActions
  .map(obj => obj.success)
  .forEach(action => {
    const type = getType(action)
    asyncActionTypes[type] = "success"
  })
asyncActions
  .map(obj => obj.failure)
  .forEach(action => {
    const type = getType(action)
    asyncActionTypes[type] = "failure"
  })

/**
 * ==============================================================
 * REDUCERS
 * ==============================================================
 */

/**
 * This reducer will check against every async action,
 * and give it a messages state
 */
export const messagesReducer = (
  state = initialMessagesState,
  action: PayloadAction<string, any>
) => {
  return produce(state, draft => {
    if (getType(messagingActions.createCustomMessage) === action.type) {
      const { title, color, text } = action.payload
      const customToast = createCustomToast(action.type, title, color, text)
      if (customToast) draft.messages = { [action.type]: customToast }
      return;
    }
    //Handling actions
    const type = asyncActionTypes[action.type]
    if (!type) return

    //Check for toast by code
    const id = action.type
    const toastByCode = getToastByErrCode(id, action?.payload?.code)
    if (toastByCode) {
      draft.messages = { [id]: toastByCode }
      return
    }
    //Check for toast by type
    const toastByType = getToastByType(id, action.type, action.payload)
    if (toastByType) {
      draft.messages = { [id]: toastByType }
      return
    }

    //Otherwise skip and dont show message

    // const id = cuid()
    // draft.messages = {
    //   [id]: {
    //     id,
    //     type: type,
    //     actionType: action.type,
    //     code: action?.payload?.code,
    //     error: action?.payload?.error,
    //   },
    // }
  })
}

/**
 * ==============================================================
 * EPICS - move this elsewhere
 * ==============================================================
 */

/**
 * ==============================================================
 * SELECTORS
 * ==============================================================
 */
