import { MeasurementsV2, MetricValues, Timestamps } from "../@types"

const removeOldTimetamps = <T>(input: Timestamps<T>, cutoff: Date) => {
  const timeValues = Object.keys(input)
  const cutoffIndex = timeValues.findIndex(v => v >= cutoff.toISOString())
  if (cutoffIndex < 0) return input
  const cutoffValues = timeValues.slice(0, cutoffIndex)
  cutoffValues.forEach(timestamp => {
    delete input[timestamp]
  })
  return input
}

export const emptyMetricValues = () => ({
  d: {},
  m: {},
  f: {},
  s: {},
  c: {},
  h: {},
  n: {},
})

/**
 *
 * @param existingMeasurements Assume sorted
 * @param newMeasurements  Assume sorted
 * @param timestampCutoff If older than this date then eject
 */
export const mergeMeasurementsV2 = (
  existingMeasurements: MeasurementsV2,
  newMeasurements: MeasurementsV2,
  timestampCutoff: Date
): MeasurementsV2 => {
  const combinedMeasurements: MeasurementsV2 = existingMeasurements;

  //Data -Combined
  Object.keys(newMeasurements.data.combined).forEach(m => {
    const metric = m as keyof MetricValues<any>
    const existingValues = existingMeasurements.data.combined[metric]
    const newValues = newMeasurements.data.combined[metric]
    const combinedValues = {
      ...existingValues,
      ...newValues,
    }
    removeOldTimetamps(combinedValues, timestampCutoff)
    combinedMeasurements.data.combined[metric] = combinedValues
  })
  //Data - Calibrations
  Object.keys(newMeasurements.data.calibrations).forEach(calibrationId => {
    const metricValues = existingMeasurements.data.calibrations[calibrationId]
      || emptyMetricValues()

    combinedMeasurements.data.calibrations[calibrationId] = emptyMetricValues()

    Object.keys(metricValues).forEach(m => {
      const metric = m as keyof MetricValues<any>
      const existingValues = metricValues[metric]
      const newCalibrationValues =
        newMeasurements.data.calibrations[calibrationId] || {}
      const newValues = newCalibrationValues[metric]
      const combinedValues = {
        ...existingValues,
        ...newValues,
      }
      removeOldTimetamps(combinedValues, timestampCutoff)
      combinedMeasurements.data.calibrations[calibrationId][
        metric
      ] = combinedValues
    })
  })

  //Heatmaps - Combined
  Object.keys(newMeasurements.heatmapRisks.combined).forEach(m => {
    const metric = m as keyof MetricValues<any>
    const existingValues = existingMeasurements.heatmapRisks.combined[metric]
    const newValues = newMeasurements.heatmapRisks.combined[metric]
    const combinedValues = {
      ...existingValues,
      ...newValues,
    }
    removeOldTimetamps(combinedValues, timestampCutoff)
    combinedMeasurements.heatmapRisks.combined[metric] = combinedValues
  })
  //Heatmaps - Calibrations
  Object.keys(newMeasurements.heatmapRisks.calibrations).forEach(
    calibrationId => {
      const metricValues = existingMeasurements.heatmapRisks.calibrations[calibrationId]
        || emptyMetricValues()

      combinedMeasurements.heatmapRisks.calibrations[
        calibrationId
      ] = emptyMetricValues()

      Object.keys(metricValues).forEach(m => {
        const metric = m as keyof MetricValues<any>
        const existingValues = metricValues[metric]
        const newCalibrationValues =
          newMeasurements.heatmapRisks.calibrations[calibrationId] || {}
        const newValues = newCalibrationValues[metric]
        const combinedValues = {
          ...existingValues,
          ...newValues,
        }
        removeOldTimetamps(combinedValues, timestampCutoff)
        combinedMeasurements.heatmapRisks.calibrations[calibrationId][
          metric
        ] = combinedValues
      })
    }
  )

  //Images - calibrations
  Object.keys(newMeasurements.images.calibrations).forEach(
    calibrationId => {
      const existingValues = existingMeasurements.images.calibrations[calibrationId] || {}

      const newValues = newMeasurements.images.calibrations[calibrationId]

      const combinedValues = { ...existingValues, ...newValues }
      removeOldTimetamps(combinedValues, timestampCutoff)
      combinedMeasurements.images.calibrations[calibrationId] = combinedValues
    }
  )
  Object.keys(newMeasurements.blurredImages.calibrations).forEach(
    calibrationId => {
      const existingValues = existingMeasurements.blurredImages.calibrations[calibrationId] || {}

      const newValues = newMeasurements.blurredImages.calibrations[calibrationId]

      const combinedValues = { ...existingValues, ...newValues }
      removeOldTimetamps(combinedValues, timestampCutoff)
      combinedMeasurements.blurredImages.calibrations[calibrationId] = combinedValues
    }
  )

  //Grouping - calibrations
  Object.keys(newMeasurements.grouping.calibrations).forEach(
    calibrationId => {
      const existingValues = existingMeasurements.grouping.calibrations[calibrationId] || {}
      const newValues = newMeasurements.grouping.calibrations[calibrationId]

      const combinedValues = { ...existingValues, ...newValues }
      removeOldTimetamps(combinedValues, timestampCutoff)
      combinedMeasurements.grouping.calibrations[calibrationId] = combinedValues
    }
  )

  //fetchedNotes - calibrations
  Object.keys(newMeasurements.fetchedNotes).forEach(calibrationId => {
    const existingValues = existingMeasurements.fetchedNotes[calibrationId]
    const newValues = newMeasurements.fetchedNotes[calibrationId]

    const combinedValues = { ...existingValues, ...newValues }
    removeOldTimetamps(combinedValues, timestampCutoff)
    combinedMeasurements.fetchedNotes[calibrationId] = combinedValues
  })

  //Grouping - combined
  const existingValues = existingMeasurements.grouping.combined
  const newValues = newMeasurements.grouping.combined
  const combinedValues = {
    ...existingValues,
    ...newValues,
  }
  removeOldTimetamps(combinedValues, timestampCutoff)
  combinedMeasurements.grouping.combined = combinedValues

  return combinedMeasurements
}
