import { MeasurementsV2, MetricValues, Timestamps } from "../@types"
import { forCalibrations } from "./forCalibrations"

const sortTimestampsAsc = <T>(input: Timestamps<T>) => {
  const output: Timestamps<T> = {}
  Object.keys(input)
    .sort()
    .forEach(timestamp => {
      output[timestamp] = input[timestamp]
    })
  return output
}

const sortMetricValues = <T>(input: MetricValues<Timestamps<T>>) => {
  const output: Partial<MetricValues<Timestamps<T>>> = {}
  Object.keys(input).forEach(m => {
    const metric = m as keyof MetricValues<any>
    const newTimestamps = sortTimestampsAsc(input[metric])
    output[metric] = newTimestamps
  })
  return output as MetricValues<Timestamps<T>>
}

export const sortMeasurements = (
  measurements: MeasurementsV2
): MeasurementsV2 => {
  return {
    data: {
      combined: sortMetricValues(measurements.data.combined),
      calibrations: forCalibrations(
        measurements.data.calibrations,
        sortMetricValues
      ),
    },
    heatmapRisks: {
      combined: sortMetricValues(measurements.heatmapRisks.combined),
      calibrations: forCalibrations(
        measurements.heatmapRisks.calibrations,
        sortMetricValues
      ),
    },
    images: {
      calibrations: forCalibrations(
        measurements.images.calibrations,
        sortTimestampsAsc
      ),
    },
    blurredImages: {
      calibrations: forCalibrations(
        measurements.blurredImages.calibrations,
        sortTimestampsAsc
      ),
    },
    status: measurements.status,
    fetchedNotes: sortTimestampsAsc(measurements.fetchedNotes),
    grouping: {
      combined: sortTimestampsAsc(measurements.grouping.combined),
      calibrations: forCalibrations(
        measurements.grouping.calibrations,
        sortTimestampsAsc
      ),
    },
  }
}
